<template>
  <div>
    <ListPatientsAdminSite></ListPatientsAdminSite>
  </div>
</template>

<script>

import ListPatientsAdminSite from '@core/components/patients/ListPatientAdminSite.vue'

export default {
  components: {
    ListPatientsAdminSite,
  },
  data() {
    return {}
  },
}
</script>

<style>

</style>
